import fetch from '~/services/httpRequest';
import {ITenantModule} from "~/admin/central-admin/Interfaces/IModuleInterfaces";

export const useModule = () => {

    const fetchAllModules = async (): Promise<ITenantModule[]|null> => {
        try {

            const result = await fetch.get(`/api/modules/all`);
            if (!result.data.status) {
                throw new Error('Error server request');
            }

            return (result.data.data?.modules ?? null);
        } catch (e) {
            console.error(e);
            return null;
        } finally {}
    };

    return {
        fetchAllModules,
    };
}
