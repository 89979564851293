<script setup>
import AdminLeftSidebar from "~/admin/components/sidebar/AdminLeftSidebar.vue";
import AdminHeader from "~/admin/components/header/AdminHeader.vue";
import AdminRightSidebar from "~/admin/components/sidebar/AdminRightSidebar.vue";

import {computed, reactive} from "vue";
import {useRoute} from "vue-router";
import Alert from "~/components/shared/alert/Alert.vue";

const route = useRoute();
const data = reactive({
    menu: [
        {
            headTitle: "dashboard",
        },
        {
            path: `/`,
            title: "Dashboard",
            type: "link",
            icon: "ti-home",
            active: true,
            defaultActive: false,
        },{
            path: `/`,
            title: "Dashboard",
            type: "link",
            icon: "ti-home",
            active: true,
            defaultActive: false,
        },
        {
            title: "Crypto Currencies",
            icon: "ti-wallet",
            type: "sub",
            active: false,
            defaultActive: false,
            children: [
                {
                    path: `${import.meta.env.BASE_URL}crypto/crypto-dashboard`,
                    title: "Dashboard",
                    type: "link",
                    active: false,
                    defaultActive: false,
                },
                {
                    path: `${import.meta.env.BASE_URL}crypto/marketcap`,
                    title: "Marketcap",
                    type: "link",
                    active: false,
                    defaultActive: false,
                },
                {
                    path: `${import.meta.env.BASE_URL}crypto/currency-exchange`,
                    title: "Currency Exchange",
                    type: "link",
                    active: false,
                    defaultActive: false,
                },
                {
                    path: `${import.meta.env.BASE_URL}crypto/buy-sell`,
                    title: "Buy & Sell",
                    type: "link",
                    active: false,
                    defaultActive: false,
                },
                {
                    path: `${import.meta.env.BASE_URL}crypto/wallet`,
                    title: "Wallet",
                    type: "link",
                    active: false,
                    defaultActive: false,
                },
                {
                    path: `${import.meta.env.BASE_URL}crypto/transactions`,
                    title: "Transactions",
                    type: "link",
                    active: false,
                    defaultActive: false,
                },
            ],
        },
    ]
});

const isNotDashboard = computed(() => {
    return route.name === 'sign-in' || route.name === '404';
});
</script>

<template>
    <div class="horizontalMenucontainer" v-if="!isNotDashboard">
        <AdminRightSidebar />
        <Alert />
        <div class="page">
            <AdminHeader />
            <AdminLeftSidebar :dataMenu="data.menu"/>
            <div class="main-content app-content">
                <div class="container-fluid">
                    <router-view />
                </div>
            </div>
        </div>
    </div>

    <div class="page" v-if="isNotDashboard">
        <router-view />
    </div>
</template>
