<script setup lang="ts">
import ToggleSwitch from "~/admin/central-admin/components/inputs/toggleSwitch.vue";
import BasicModal from "~/admin/central-admin/components/modal/BasicModal.vue";

import {useRouter, useRoute} from "vue-router";
import {useTenant} from "~/admin/central-admin/composables/tenant/useTenant";
import {computed, onMounted, reactive} from "vue";
import {ITenant} from "~/admin/central-admin/Interfaces/ITenantInterfaces";
import {useModule} from "~/admin/central-admin/composables/module/useModule";
import {ITenantModule} from "~/admin/central-admin/Interfaces/IModuleInterfaces";
import {useGlobalModal} from "~/composables/useGlobalModal";
import {useTenantSetting} from "~/admin/central-admin/composables/tenant/useTenantSetting";



const {updateTenantModules} = useTenantSetting();
const isChangeStatusModal = useGlobalModal();
const {fetchAllModules} = useModule();
const {fetchTenant} = useTenant();
const router = useRouter();
const route = useRoute();

const data = reactive({
    tenant: <ITenant> {},
    modules: <ITenantModule[]|[]> [],
    isShowChangeStatusModal: false,
    clickCurrentModule: {
        name: '',
        status: ''
    }
});

onMounted(async () => {
    const tenantId = Number(route.params.tenantId);
    const tenant = <ITenant|null> await fetchTenant(tenantId);
    const modules = <ITenantModule[]|null> await fetchAllModules();

    if (tenant === null) {
        return router.push('404');
    }

    if (modules) {
        data.modules = modules;
    }

    data.tenant = <ITenant> tenant;
});

const switchModule = async (e, moduleId) => {
    let currentModule = <ITenantModule|null>data.modules.find((module: ITenantModule) => module.id === moduleId);
    if (currentModule) {
        data.clickCurrentModule.name = currentModule.name;
        data.clickCurrentModule.status = e;
    }
    data.isShowChangeStatusModal = true;
    const isLeave = await isChangeStatusModal.ask();

    if (!isLeave) {
        data.isShowChangeStatusModal = false;
        return;
    }
    data.isShowChangeStatusModal = false;

    let toggleModules = [];
    if (e) {
        let module = <ITenantModule> data.modules.find((mod: ITenantModule) => mod.id === moduleId);
        module && toggleModules.push(...data.tenant.modules, module);
    }

    if (!e) {
        toggleModules = data.tenant.modules.filter((tenantModule: ITenantModule) => tenantModule.id !== moduleId);
    }

    const modules = await updateTenantModules(data.tenant.id, toggleModules);

    data.clickCurrentModule.name = '';
    data.clickCurrentModule.status = '';

    if (!modules) {
        return;
    }

    data.tenant.modules = modules;
};

const getTenantModules = <ITenantModule[]|[]> computed(() => {
    return data.modules.map((module: ITenantModule): ITenantModule => {
        //@ts-ignore
        let isModule = Boolean(data.tenant.modules.find((tenantModule: ITenantModule) => tenantModule.id === module.id));
        isModule && (module.is_active = true);
        !isModule && (module.is_active = false);

        return module;
    });
});
</script>

<template>
    <div
        v-if="data.tenant"
        class="row square"
    >
        <div class="col-lg-12 col-md-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div class="panel profile-cover">
                        <div class="profile-cover__info">
                            <h3 class="h3">{{ data.tenant.name }}</h3>
                            <ul class="nav">
                                <li><strong>{{ data.tenant.count_users }}</strong>Пользователей</li>
<!--                                <li><strong>33</strong>Followers</li>-->
<!--                                <li><strong>136</strong>Following</li>-->
                            </ul>
                        </div>
                    </div>
                    <div class="profile-tab tab-menu-heading">
                        <nav class="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                            <a class="nav-link active" data-bs-toggle="tab" href="#about">About</a>

                            <a class="nav-link" data-bs-toggle="tab" href="#settings">Account Settings</a>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row row-sm">
        <div class="col-lg-12 col-md-12">
            <div class="card custom-card main-content-body-profile">
                <div class="tab-content">
                    <div class="main-content-body tab-pane p-4 border-top-0 active" id="about">
                        <div class="card-body p-0 border p-0 rounded-10">
                            <div class="p-4">
                                <h4 class="fs-15 text-uppercase mb-3">BIOdata</h4>
                                <p class="m-b-5">
                                    Информация о арендаторе
                                </p>
                            </div>
                        </div>
                    </div>



                    <div class="main-content-body tab-pane p-4 border-top-0" id="settings">
                        <div class="card-body border" data-select2-id="12">
                            <form class="form-horizontal" data-select2-id="11">
                                <div class="mb-4 main-content-label">Модули</div>

                                <div class="col-xl-4">
                                    <div class="card custom-card">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <table class="table text-nowrap table-striped table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Модуль</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                        v-for="module in getTenantModules"
                                                        :key="module.id"
                                                    >
                                                        <th scope="row">{{ module.name }}</th>
                                                        <td>
                                                            <span
                                                                class="badge bg-success-transparent "
                                                                :class="{'bg-success-transparent': module.is_active, 'bg-danger-transparent': !module.is_active}"
                                                            >
                                                                <i class="ri-d align-middle me-1"
                                                                   :class="{'ri-check-fill': module.is_active, 'ri-close-fill': !module.is_active}"
                                                                ></i>
                                                                {{ module.is_active ? 'Active' : 'Canceled' }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <toggleSwitch
                                                                :isDisabled="module.id === 1"
                                                                customClass="toggle-sm mb-0 toggle-success"
                                                                v-model="module.is_active"
                                                                :isModelValue="false"
                                                                @changeEvent="switchModule($event, module.id)"
                                                            />
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<BasicModal
    v-if="data.isShowChangeStatusModal"
    head="Переключение модуля"
    @closeEvent="closeModal"
>
    <template #body>
        <p>Вы действительно хотите {{ data.clickCurrentModule.status ? 'включить' : 'выключить' }} модуль "{{ data.clickCurrentModule.name }}"?</p>
    </template>
    <template #close-btn>
        <button
            class="btn-close"
            type="button"
            @click="isChangeStatusModal.tell(false)"
        ></button>
    </template>
    <template #footer>
        <button
            class="btn ripple btn-success"
            type="button"
            @click="isChangeStatusModal.tell(true)"
        >
            Да
        </button>

        <button
            class="btn ripple btn-secondary"
            type="button"
            @click="isChangeStatusModal.tell(false)"
        >
            Нет
        </button>
    </template>
</BasicModal>

</template>

<style scoped lang="scss">
.profile-cover__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
