<script setup lang="ts">
const emits = defineEmits(['closeEvent']);
const props = defineProps<{
    title?: String,
    head: String,
    body?: String
}>();

const close = () => {
    emits('closeEvent');
};
</script>

<template>
    <div class="modal fade show" id="modaldemo1" style="display: block; padding-right: 4px;">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6
                        class="modal-title"
                        v-if="props.title"
                    >{{ props.title }}</h6>
                    <slot name="close-btn">
                        <button
                            class="btn-close"
                            type="button"
                            @click="close"
                        ></button>
                    </slot>
                </div>
                <div class="modal-body">
                    <h6>{{ props.head }}</h6>
                    <slot name="body">
                        <p>
                            {{ props.body }}
                        </p>
                    </slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">

                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
