import fetch from '~/services/httpRequest';
import {ITenant} from "~/admin/central-admin/Interfaces/ITenantInterfaces";

export const useTenant = () => {

    const fetchTenant = async (tenantId: number): Promise<ITenant|null> => {
        try {

            const result = await fetch.get(`/api/tenant-profile/${tenantId}`);
            if (!result.data.status) {
                throw new Error('Error server request');
            }

            return (result.data.data?.tenant ?? null);
        } catch (e) {
            console.error(e);
            return null;
        } finally {}
    };

    return {
        fetchTenant,
    };
}
