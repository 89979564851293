import fetch from '~/services/httpRequest';
import {ITenantModule} from "~/admin/central-admin/Interfaces/IModuleInterfaces";
import {useAlertStore} from "~/store/useAlertStore";
import {ALERT_ERROR, ALERT_SUCCESS} from "~/store/constant_variables";

export const useTenantSetting = () => {
    const { setInfo } = useAlertStore();
    const updateTenantModules = async (tenantId: number, modules: ITenantModule[]) => {
        try {
            const data = {
                tenantId: tenantId,
                modules: prepareUpdateModules(modules)
            };
            const result = await fetch.post(`/api/tenant-settings/modules/update`, data);
            if (!result.data.status) {
                throw new Error('Error server request');
            }
            setInfo({type: ALERT_SUCCESS, message: ['Модули успешно обновлены']});
            return (result.data.data?.modules ?? null);
        } catch (e) {
            setInfo({type: ALERT_ERROR, message: ['Не удалось обновить модули']});
            console.error(e);
            return null;
        } finally {}
    };

    const prepareUpdateModules = (modules: ITenantModule[]) => {
        let arrIds = [];

        modules.forEach((module: ITenantModule) => {
            arrIds.push(module.id);
        });

        return arrIds;
    }

    return {
        updateTenantModules,
    };
}
