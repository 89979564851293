<script setup lang="ts">
import {onBeforeMount, reactive} from "vue";
const emits = defineEmits(['update:modelValue', 'changeEvent']);

const props = defineProps<{
    modelValue: Boolean
    customClass: '',
    isModelValue: Boolean,
    isDisabled?: Boolean,
}>();

const toggleState = (): void => {
    const value = !props.modelValue;
    props.isModelValue && emits('update:modelValue', value);
    emits('changeEvent', value);
};
</script>

<template>
    <div
        class="toggle"
        :class="[props.customClass, { 'on': props.modelValue, 'disable-switch': props.isDisabled }]"
        @click="toggleState"
    >
        <span></span>
    </div>
</template>

<style scoped lang="scss">
.disable-switch {
    opacity: .5;
    pointer-events: none;
}
</style>
