import fetch from '~/services/httpRequest';
import {usePageLoaderStore} from "~/store/usePageLoaderStore";
import {IDashboard} from "~/admin/central-admin/composables/dashboard/interfaces/IDashboard";



export const useDashboard = () => {

    const fetchDashboard = async (): Promise<IDashboard[]|null> => {
        try {

            const result = await fetch.get(`/api/dashboard`);
            if (!result.data.status) {
                throw new Error('Error server request');
            }

            return result.data.data;
        } catch (e) {
            console.error(e);
            return null;
        } finally {}
    };

    return {
        fetchDashboard,
    };
}
