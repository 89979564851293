<script setup lang="ts">
import {onMounted, reactive} from "vue";
import {useDashboard} from "~/admin/central-admin/composables/dashboard/useDashboard"
import {classByTenantStatus} from '~/admin/central-admin/helpers/helpers';
import {IDashboard} from "~/admin/central-admin/composables/dashboard/interfaces/IDashboard";
import {ITenant} from "~/admin/central-admin/Interfaces/ITenantInterfaces";

const {fetchDashboard} = useDashboard();

const data = reactive({
    tenants: <ITenant[]|[]> [],
    tenantsTableTitles: <string[]> [
        'ID',
        'Название',
        'Доменное имя',
        'База данных',
        'Кол-во пользователей',
        'Дата создания',
        'Статус',
    ]
});

onMounted(async () => {
    const result = <IDashboard> await fetchDashboard();
    if (result) {
        data.tenants = <ITenant[]> result.tenants
    }
});


</script>

<template>
    <div class="card custom-card">
        <div class="card-header border-bottom-0">
            <label class="main-content-label my-auto pt-2">Сиписок арендаторов</label>
        </div>
        <div class="card-body pt-3">
            <div class="table-responsive tasks">
                <table class="table card-table table-vcenter text-nowrap border text-start">
                    <thead>
                    <tr>
                        <th
                            class="wd-lg-10p"
                            v-for="(title, key) in data.tenantsTableTitles"
                            :key="key"
                        >{{ title }}</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr
                        v-for="tenant in data.tenants"
                        :key="tenant.id"
                    >
                        <td>{{ tenant.id }}</td>
                        <td class="coin_icon mt-2">
                            <div class="d-flex">
                                <router-link :to="`/tenant-profile/${tenant.id}`">
                                    <span class=" my-auto">{{ tenant.name }}</span>
                                </router-link>

                            </div>
                        </td>
                        <td>{{ tenant.domain }}</td>
                        <td>{{ tenant.database }}</td>
                        <td>{{ tenant.count_users }}</td>
                        <td>{{ tenant.created_at }}</td>
                        <td class="">
                            <p :class="'text-' + classByTenantStatus(tenant.status)">
                                {{ tenant.status_text }}
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
